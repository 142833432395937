import React from 'react';


const CalendlyButton = () => {
  const handleCalendlyClick = () => {
    window.open('https://calendly.com/fullstackjoe-dev/discovery-session', '_blank');
  };

  return (
    <button
      onClick={handleCalendlyClick}
      className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none   
 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"   

    >
      Book An Appointment 
    </button>
  );
};

export default CalendlyButton;