import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import poshtraining from '../assets/img/poshtraining.webp';
import poshtraining2 from '../assets/img/poshtraining2.webp';
import poshtraining3 from '../assets/img/poshtraining3.webp';
import poshtraining4 from '../assets/img/poshtraining4.webp';
import caroline1 from '../assets/img/caroline1.webp';
import caroline2 from '../assets/img/caroline2.webp';
import caroline3 from '../assets/img/caroline3.webp';
import caroline4 from '../assets/img/caroline4.webp';
import toone1 from '../assets/img/toone1.webp';
import toone2 from '../assets/img/toone2.webp';
import pb1 from '../assets/img/pb1.webp';
import pb2 from '../assets/img/pb2.webp';
import jd1 from '../assets/img/jd1.webp';
import jd2 from '../assets/img/jd2.webp';
import jd3 from '../assets/img/jd3.webp';
import raw1 from '../assets/img/raw1.webp';
import raw2 from '../assets/img/raw2.webp';
import raw3 from '../assets/img/raw3.webp';
import raw4 from '../assets/img/raw3.webp';

const portfolioItems = [
  {
    name: 'Caroline Sandiford',
    description: 'Caroline is a qualified and accredited Legal Executive, specialising in Wills, Probate and Lasting Powers of Attorney.',
    link: 'https://carolinesandiford.uk/',
    images: [caroline1, caroline2, caroline3, caroline4],
  },
  {
    name: 'Raw Enduro',
    description: 'Providers of Enduro Events, Play Days, Training Schools, and Try Out Experience Days.',
    link: 'https://rawenduro.co.uk/',
    images: [raw1, raw2, raw3, raw4],
  },
  {
    name: 'PoshTraining',
    description: 'An online training platform for the beauty industry, offering AET & CAVA courses with flexible pricing, payment plans and a bespoke student portal.',
    link: 'https://www.poshtraining.co.uk/',
    images: [poshtraining, poshtraining2, poshtraining3, poshtraining4],
  },
  {
    name: 'Toone Skips',
    description: 'Toone Waste Management are experts in waste disposal and material cycling in Tyldesley, Greater Manchester.',
    link: 'https://toone-skips.co.uk/',
    images: [toone1,toone2],
  },
  {
    name: 'Pemberley Calligraphy',
    description:'Joe set up my WordPress site in no time at all, handling all things technical in the background and allowing me to focus on the design. The whole process was seamless and I highly recommend working with him."',
    link: 'https://pemberleycalligraphy.co.uk/',
    images: [pb1,pb2],
  },
  {
    name: 'JD Driveways & Landscapes',
    description:'The premier choice for quality driveways and landscaping services in the North West',
    link: 'https://jddrivewayslandscapes.co.uk/',
    images: [jd1,jd2,jd3],
  }
];

const PortfolioSection = () => {
  return (
    <div className="portfolio-section bg-dark" id="portfolio-section">
      <Container>
        <Row>
          <Col>
            <h1 className="centered-text">My Portfolio</h1>
            <p className="centered-text">Here are some of the most recent projects I've worked on:</p>
          </Col>
        </Row>
        <Row>
          {portfolioItems.map((item, index) => (
            <Col md={4} xs={12} key={index} className="mb-4"> 
              <PortfolioItem item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

const PortfolioItem = ({ item }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const itemRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 } 
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let intervalId;

    if (isInView) {
      intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % item.images.length);
      }, 5000); // Increased interval for slower transitions
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isInView, item.images.length]);

  return (
    <a
      href={item.link}
      className="portfolio-item glow-on-hover"
      ref={itemRef}
    >
      {/* Lazy load images and apply smooth transitions */}
      <div
        className="portfolio-item-background"
        style={{ 
          backgroundImage: isInView ? `url(${item.images[currentImageIndex]})` : 'none',
          transition: 'background-image 0.5s ease-in-out', // Smooth transition
          willChange: 'background-image', // Optimize for animation
        }}
      ></div> 

      <div className="portfolio-item-content">
        <h2>{item.name}</h2>
        <p>{item.description}</p>
      </div>
    </a>
  );
};

export default PortfolioSection;